import React from 'react';

const ShowCurrentBox = ({ steps }) => {
  return (
    <div className="header-transition">
      {steps === false ? (
        <div className="head-transition-indicator">
          <div className="left-indicator"></div>
          <div className="right-indicator"></div>
        </div>
      ) : (
        <div className="head-transition-indicator">
          <div
            className="left-indicator"
            style={{ backgroundColor: '#dfdfdf' }}
          ></div>
          <div
            className="right-indicator"
            style={{ backgroundColor: '#d0342f' }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default ShowCurrentBox;
