import React, { useState, useEffect } from 'react';
import { Button, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Parser from 'react-xml-parser';
import CustomModal from 'components/common/CustomModal';
import XMLParser from 'react-xml-parser';

const SavingsTermsModal = ({
  open,
  onClose,
  bodyText,
  onAgreeTermsAndCondition,
}) => {
  const xml = !bodyText
    ? null
    : new XMLParser().parseFromString(bodyText);

  const renderParagraphs = paragraphs =>
    paragraphs.map((paragraph, index) => (
      <p key={index} style={paragraph.attributes.style}>
        {paragraph.value}
      </p>
    ));

  return (
    <CustomModal open={open} size={'tiny'}>
      <div className="container__column">
        <div className="form-box__item">
          <div className="form-box">
            <h3>{global.translate('Apply for savings')}</h3>
            <span style={{ fontSize: '16', fontWeight: '500' }}>
              {global.translate('Terms and Conditions')}
            </span>

            <div>
              <div className="display-flex-column">
                <p>
                  {global.translate(
                    `Opening a Savings Account  is subject to eligibility criteria. Please review the terms and conditions and ensure you meet the necessary requirements.`,
                  )}
                </p>
                <p>
                  {global.translate(
                    `Interest rates on Savings Accounts may vary and are subject to change. It is essential to stay updated on the latest interest rate information by visiting our official website or contacting our customer support.`,
                  )}
                </p>
                <p>
                  {global.translate(
                    `Protect your account information, including login credentials, account numbers, and personal identification details. We will never ask for sensitive information via email or phone calls.`,
                  )}
                </p>
                <p>
                  {global.translate(
                    `We take your privacy seriously. Please review our privacy policy to understand how we collect, use, and safeguard your personal information.`,
                  )}
                </p>
                <p>
                  {global.translate(
                    `By applying for a Savings Account, you agree to abide by all applicable laws and regulations governing your account and financial transactions.`,
                  )}
                </p>
                <p>
                  {global.translate(
                    `We reserves the right to modify the terms and conditions of the Savings Account and this disclaimer. Any updates will be communicated to account holders.`,
                  )}
                </p>
                <p>
                  {global.translate(
                    `By proceeding with your application, you acknowledge that you have read and understood the terms and conditions outlined in this disclaimer.`,
                  )}
                </p>
              </div>
              <div className="large-margin-top">
                <a href="https://2u.money/microloan-terms-and-conditions?view=simple&lang=en&platform=m2u&country=cm">
                  {global.translate(
                    'Click here for more information.',
                  )}
                </a>
              </div>
            </div>

            <div style={{ marginTop: '20px' }}>
              <Button className="close-btn__style" onClick={onClose}>
                {global.translate('Cancel')}
              </Button>

              <Button
                className="save-btn"
                style={{ width: '180px' }}
                onClick={onAgreeTermsAndCondition}
              >
                {global.translate('Agree & Continue')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

SavingsTermsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default SavingsTermsModal;
