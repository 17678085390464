import React from 'react';
import m2uIcon from 'assets/images/m2uIcon.svg';
import PhoneInput from 'react-phone-input-2';
import { Input, Button, Image } from 'semantic-ui-react';

const ToOthers = props => {
  const {
    selectedAccountNumber,
    onHandleGetAccountNumber,
    locateWallet,
    onSearchAccountNumber,
    walletFound,
    onHandleChangeAmount,
    userData,
    form,
    loading,
    enableNextProcess,
    currentOption,
    onSubmitWalletTransactionConfirmation,
    setCurrentPage,
    setForm,
  } = props;
  return (
    <>
      <div className="card-box__field">
        <span className="card-title">
          {global.translate('Choose recipient')}
        </span>
        <div className="card-box__items-field">
          <div className="card-box__items-field-btn">
            <Input
              className="icon"
              labeled
              value={selectedAccountNumber}
              onChange={onHandleGetAccountNumber}
              placeholder={global.translate(
                'Type wallet number, username or phone number',
              )}
              style={{ border: 'none', width: '90%' }}
            />
            <Button
              icon="search"
              loading={locateWallet?.loading}
              onClick={onSearchAccountNumber}
            />
          </div>
          <div className="small-separator"></div>
          {walletFound &&
            walletFound?.map(item => (
              <div className="user-found-box">
                {item?.PictureURL !== '' ? (
                  <div
                    className="orval-shape"
                    style={{ background: 'transparent' }}
                  >
                    <Image src={item?.PictureURL} />
                  </div>
                ) : (
                  <div className="orval-shape">
                    <span className="label">
                      {`${item?.FirstName}`.charAt(0)}
                      {`${item?.LastName}`.charAt(0)}
                    </span>
                  </div>
                )}

                <div className="items_content">
                  <span className="btn-title__top">
                    {item?.FirstName} {item?.LastName}
                  </span>
                  <span className="btn-title__bottom">
                    {item?.WalletNumber}
                  </span>
                </div>
              </div>
            ))}
          {!walletFound ? (
            <div className="warning-box">
              <span>
                {global.translate(
                  'You haven’t selected any contact yet.',
                )}
              </span>
            </div>
          ) : null}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div className="card-box__field" style={{ width: '49%' }}>
            <span className="card-title">
              {global.translate('First name')}
            </span>
            <div className="input-field">
              <Input
                value={form?.firstName}
                onChange={onHandleChangeAmount}
                name="text"
                placeholder={global.translate('Your first name')}
              />
            </div>
          </div>
          <div className="card-box__field" style={{ width: '49%' }}>
            <span className="card-title">
              {global.translate('Last name')}
            </span>
            <div className="input-field">
              <Input
                value={form?.lastName}
                onChange={onHandleChangeAmount}
                name="text"
                placeholder={global.translate('Your last name')}
              />
            </div>
          </div>
        </div>
        <div className="card-box__field">
          <span className="card-title">
            {global.translate('Phone number')}
          </span>
          <div
            className="operator-input"
            style={{ marginTop: '10px' }}
          >
            <PhoneInput
              disabled={false}
              country={userData?.Country?.toLowerCase()}
              enableSearch
              value={form.phoneNumber}
              currentOption={form.phoneNumber}
              name="text"
              containerClass="phone-number-container"
              inputClass="phone-number-input"
              onChange={(phone, data) => {
                setForm({
                  phoneNumber: phone,
                  countryCode: data.countryCode,
                });
              }}
              placeholder={global.translate('Phone Number')}
            />
          </div>
        </div>
        <div className="card-box__field">
          <span className="card-title">
            {global.translate('Amount')}
          </span>
          <div className="input-field">
            <Input
              label={{
                basic: true,
                content: currentOption?.CurrencyCode,
              }}
              labelPosition="right"
              value={form?.amount}
              onChange={onHandleChangeAmount}
              name="amount"
              placeholder={global.translate('0.0')}
            />
          </div>
        </div>
      </div>

      <div
        className="bottom-transition__box"
        style={{ marginTop: '-15px' }}
      >
        <Button id="btn-back" onClick={() => setCurrentPage(0)}>
          {global.translate('Back')}
        </Button>

        <Button
          onClick={() => onSubmitWalletTransactionConfirmation()}
          loading={loading}
          disabled={enableNextProcess}
          style={{ marginLeft: '10px' }}
        >
          {global.translate('Proceed')}
        </Button>
      </div>
    </>
  );
};

export default ToOthers;
